import axios from "axios";
import envService from "./env.service";

export const getVersions = (
  entr_name: string,
  org_name: string,
  env_name: string,
) => {
  return axios.get(
    `${envService.window._env_.REACT_APP_API_URL}/ecosystem/versions/${entr_name}/${org_name}/${env_name}`,
  );
};

export const getEnvironments = (entr_name: string, org_name: string) => {
  return axios.get(
    `${envService.window._env_.REACT_APP_API_URL}/ecosystem/environments/${entr_name}/${org_name}`,
  );
}

export const getOrganizations = () => {
  return axios.get(
    `${envService.window._env_.REACT_APP_API_URL}/ecosystem/organizations`,
  );
};
