import * as React from "react";
import {useEffect, useState} from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {Link} from "react-router-dom";
import { getOrganizations } from "../../services/ecosystem.service";

interface IOrganization {
  id: string;
  name: string;
  enterprise: string;
  path: string;
  text: string;
}
const defaultOrganizations:IOrganization  = { id: "", name: "", enterprise: "", path: "", text: "" };
type Organizations = [IOrganization];
export default function SecondaryListItems() {
  const [organizations, setOrganizations] = useState<Organizations>([defaultOrganizations]);
  useEffect(() => {
    getOrganizations()
      .then((response: { data: React.SetStateAction<any> }) => {
        const rows = response.data.map((org: IOrganization) => { 
          return {
            "id": crypto.randomUUID().toString(),
            "name": org.name,
            "enterprise": org.enterprise,
            "path": `/${org.enterprise}/${org.name}`,
            "text": `${org.enterprise} - ${org.name}`
          }
        });
        setOrganizations(rows);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, []);
  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        System Versions 
      </ListSubheader>
      {organizations.map(row => (        
          <ListItemButton component={Link} to={row.path} key={row.id}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary={row.text} />
          </ListItemButton>
      ))}
    </React.Fragment>
  );
}
