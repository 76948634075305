function isProduction(): boolean {
  if (process.env.REACT_APP_NODE_ENV === "staging") return false;
  return (
    process.env.NODE_ENV === "production" ||
    process.env.REACT_APP_NODE_ENV === "production"
  );
}

interface customWindow extends Window {
  _env_?: any;
}
declare const window: customWindow;

const envService = {
  isProduction,
  window,
};

export default envService;
