import * as React from "react";
import {useEffect, useState} from "react";
import {Grid, Paper} from "@mui/material";
import ApiInfoCard from "./api-info-card";
import {getEnvironments} from "../../services/ecosystem.service";

interface IEnvironment {
  id: string;
  name: string;
}
const defaultEnvironment  = { id: "", name: "" };
type Environemnts = [IEnvironment];
export default function ApiInfoGrid(props: {
  entr_name: string;
  org_name: string;
}) {
  const [environments, setEnvironments] = useState<Environemnts>([defaultEnvironment]);
  useEffect(() => {
    getEnvironments(props.entr_name, props.org_name)
      .then((response: { data: React.SetStateAction<any> }) => {
        const rows = response.data.map((env: any) => { 
          return {"id": crypto.randomUUID().toString(), "name": env }
        });
        setEnvironments(rows);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, []);

  return (
    <React.Fragment>
      {environments.map(row => (        
          <Grid item xs={12} md={4} lg={4} key={row.id}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}>
              <ApiInfoCard
                entr_name={props.entr_name!}
                org_name={props.org_name!}
                env_name={row.name}
                key={row.id}
              />
            </Paper>
          </Grid>
      ))}
    </React.Fragment>
  );
}
