import * as React from "react";
import Title from "../title/title";
import {useEffect, useState} from "react";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import {getTags} from "../../services/azurerm.service";
import {getVersions} from "../../services/ecosystem.service";

export default function ApiInfoCard(props: any) {
  const entr_name = props.entr_name!;
  const org_name = props.org_name!;
  const env_name = props.env_name!;
  const [versions, setVersion] = useState<
    [
      {
        api_type: string;
        version: string;
      },
    ]
  >([
    {
      api_type: "",
      version: "",
    },
  ]);
  const [tagVersions, setTagVersion] = useState<
    [
      {
        system_type: string;
        version: string;
      },
    ]
  >([
    {
      system_type: "",
      version: "",
    },
  ]);
  useEffect(() => {
    if (entr_name === "" || org_name === "" || env_name === "") {
      return;
    }
    getTags(entr_name, org_name, env_name)
      .then((response: {data: React.SetStateAction<any>}) => {
        setTagVersion(response.data.details[0].versions);
        console.log(response.data.details[0].versions);
      })
      .catch((error: any) => {
        console.error(error);
      });
    getVersions(entr_name, org_name, env_name)
      .then((response: {data: React.SetStateAction<any>}) => {
        setVersion(response.data.details[0].versions.details);
        console.log(response.data.details[0].versions.details);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, []);

  return (
    <React.Fragment>
      <Title>
        {entr_name} / {org_name} / {env_name}
      </Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>System</TableCell>
            <TableCell>Version</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {versions.map(row => (
            <TableRow key={row.api_type}>
              <TableCell>{row.api_type}</TableCell>
              <TableCell>{row.version}</TableCell>
            </TableRow>
          ))}
          {tagVersions.map(row => (
            <TableRow key={row.system_type}>
              <TableCell>{row.system_type}</TableCell>
              <TableCell>{row.version}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
