import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {Divider} from "@mui/material";
import SecondaryListItems from "./listitem-secondary-items";

export const mainListItems = (
  <React.Fragment>
    <ListItemButton>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <Divider variant="middle" textAlign="center" />
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <SecondaryListItems />
  </React.Fragment>
);
