import axios from "axios";
import envService from "./env.service";

export const getTags = (
  entr_name: string,
  org_name: string,
  env_name: string,
) => {
  return axios.get(
    `${envService.window._env_.REACT_APP_API_URL}/azurerm/gettags/${entr_name}/${org_name}/${env_name}`,
  );
};
