import "./App.css";
import Dashboard from "./components/dashboard/Dashboard";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import React from "react";

const router = createBrowserRouter([{path: "*", Component: Root}]);

export default function App() {
  return <RouterProvider router={router} />;
}

function Root() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/:entr_name/:org_name" element={<Dashboard />} />
    </Routes>
  );
}
